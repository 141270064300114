import useNotification from "hooks/useNotification";
import { jettonDeployController } from "lib/deploy-controller";
import { useEffect, useState } from "react";
import useJettonStore from "store/jetton-store/useJettonStore";
import { AppButton } from "components/appButton";
import { validateTransfer } from "./utils";
import { ButtonWrapper, TransferContent, TransferWrapper } from "./styled";
import { AppHeading } from "components/appHeading";
import { AppNumberInput, AppTextInput } from "components/appInput";
import { toDecimalsBN } from "utils";
import { useRecoilState, useSetRecoilState } from "recoil";
import { jettonActionsState } from "pages/jetton/actions/jettonActions";
import { TonConnectUI, useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import Store from "pages/jetton/Store";
import { Address, Cell, TonClient } from "ton";
import { zeroAddress } from "lib/utils";
import { useSearchParams } from "react-router-dom";

export const TransferAction = () => {
  const { balance, symbol, jettonWalletAddress, getJettonDetails, isMyWallet, decimals } =
    useJettonStore();

  const [realBalance, setRealBalance] = useState<Number>(0);
  const [toAddress, setToAddress] = useState<string | undefined>(undefined);
  // const [amount, setAmount] = useState<number | undefined>(undefined);
  const { showNotification } = useNotification();
  const connectedWalletAddress = useTonAddress();
  const [tonconnect] = useTonConnectUI();
  const [actionInProgress, setActionInProgress] = useRecoilState(jettonActionsState);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  async function updateWalletOnDB() {
    const res = await fetch("/api/wallet-update", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        token: `${token}`,
      },
      body: JSON.stringify({
        wallet: connectedWalletAddress,
      }),
    }).then((res) => res.json());

    console.log("updateWalletOnDB ~ res:", res);
  }

  useEffect(() => {
    if (!connectedWalletAddress) return;

    updateWalletOnDB();
  }, [connectedWalletAddress, token]);

  useEffect(() => {
    console.log(balance);

    if (!balance) return;

    setToAddress("0QDriVxlC_ZHbDHJLZqUVpNgH-HuTLvXRME93YOc1JM71z2n");
    const bnConverted = Number(balance) / 1e9;
    console.log("useEffect ~ bnConverted:", bnConverted);
    setRealBalance(bnConverted);
  }, [balance]);

  // if (!balance || !jettonWalletAddress || !isMyWallet) {
  //   return null;
  // }

  const onSubmit = async (amount: any, item_id: Number) => {
    console.log(TonClient);
    const error = validateTransfer(
      toAddress,
      toDecimalsBN(amount!, decimals!),
      balance,
      symbol,
      decimals,
    );
    if (error) {
      showNotification(error, "warning", undefined, 3000);
      return;
    }

    setActionInProgress(true);
    try {
      const res = await jettonDeployController.transfer(
        tonconnect,
        toDecimalsBN(amount!.toString(), decimals!),
        toAddress!,
        connectedWalletAddress!,
        jettonWalletAddress,
      );
      // setToAddress(undefined);
      // setAmount(undefined);
      // getJettonDetails();

      setTimeout(async () => {
        const result = await jettonDeployController.getJettonDetails(
          Address.parse("EQD2CWs4yDTnkT7SpBc7VnwtoL9yAcmx3G_ni-QsaueNzVGG"),
          Address.parse(connectedWalletAddress),
        );
        console.log("onSubmit ~ result:", result);
        if (result.jettonWallet) {
          console.log("onSubmit ~ result:", result);
          setRealBalance(Number(result.jettonWallet.balance) / 1e9);
        }
      }, 3000);

      showNotification(`Transaction successfull`, "warning", undefined, 4000);

      console.log(res.boc);
      const parsedCell = Cell.fromBoc(Buffer.from(res.boc, "base64"))[0];

      // Extract the transaction hash from the parsed BOC
      const transactionHash = parsedCell.hash().toString("hex");

      updateDb(amount, transactionHash, item_id);

      console.log("Transaction hash:", transactionHash);

      // const hash = await jettonDeployController.checkTxnHash(
      //   transactionHash,
      //   Address.parse(connectedWalletAddress),
      // );

      // console.log("onSubmit ~ hash:", hash);
    } catch (error) {
      if (error instanceof Error) {
        showNotification(error.message, "error");
      }
    } finally {
      setActionInProgress(false);
    }
  };

  async function updateDb(amount: Number, hash: string, item_id: Number) {
    const res = await fetch("/api/update-db", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        token: `${token}`,
      },
      body: JSON.stringify({
        amount: amount,
        hash: hash,
        id: item_id,
      }),
    }).then((res) => res.json());

    console.log("updateDb ~ res:", res);
  }

  return (
    <TransferWrapper>
      {/* <AppHeading
        text={`Transfer ${balance}`}
        variant="h4"
        fontWeight={800}
        fontSize={20}
        marginBottom={20}
        color="#161C28"
      /> */}
      <TransferContent>
        {/* <AppTextInput
          fullWidth
          label="Recipient wallet address"
          value={toAddress}
          onChange={(e) => setToAddress(e.target.value)}
        />
        <AppNumberInput
          label="Amount to transfer"
          onChange={(value: number) => setAmount(value)}
          value={amount}
        /> */}
        <Store
          onSubmit={onSubmit}
          address={connectedWalletAddress}
          balance={realBalance}
          actionInProgress={actionInProgress}
          setActionInProgress={setActionInProgress}
        />
      </TransferContent>
      <ButtonWrapper>
        {/* <AppButton
          disabled={!(toAddress && amount)}
          onClick={onSubmit}
          height={50}
          loading={actionInProgress}>
          Transfer {symbol}
        </AppButton> */}
      </ButtonWrapper>
    </TransferWrapper>
  );
};
