import {
  Box,
  Button,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { IoIosCheckmarkCircleOutline, IoMdClose } from "react-icons/io";
import { FaArrowRight, FaCheck, FaCopy } from "react-icons/fa";
import { VscError } from "react-icons/vsc";
import { useEffect, useState } from "react";
import useNotification from "hooks/useNotification";

const SwapSuccessfull = ({
  onCloseSwapSuccessfull,
  isOpenSwapSuccessfull,
  tonAddress,
  swapSlug,
  receivableAmount,
  coreWalletAddress,
}) => {
  const { showNotification } = useNotification();
  const [currentWalletAddress, setCurrentWalletAddress] = useState("");

  useEffect(() => {
    if (!tonAddress && !coreWalletAddress) return;

    if (tonAddress) {
      setCurrentWalletAddress(tonAddress);
    } else {
      setCurrentWalletAddress(coreWalletAddress);
    }
  }, [coreWalletAddress, tonAddress]);

  function copyToClipBoard(content) {
    navigator.clipboard.writeText(content);
    showNotification(`Slug copied to clipboard`, "success", undefined, 4000);
  }

  return (
    <Modal
      size={"sm"}
      isCentered
      motionPreset="slideInBottom"
      onClose={onCloseSwapSuccessfull}
      isOpen={isOpenSwapSuccessfull}>
      <ModalOverlay />
      <ModalContent
        className="modal-content"
        borderRadius={"30px"}
        padding={"0px"}
        mx={"10px"}
        overflow={"hidden"}>
        <ModalHeader
          zIndex={"2"}
          color={"#51A1FE"}
          fill={"#51A1FE"}
          className="modal-header"
          display={"flex"}
          gap={"10px"}
          alignItems={"center"}>
          {swapSlug ? <IoIosCheckmarkCircleOutline /> : <VscError />}
          <Text w={"100%"}>Swap {swapSlug ? "Successfull" : "Failed"}</Text>
        </ModalHeader>
        <ModalBody className="modal-body">
          <Box className="swap-confirmed-container">
            {swapSlug ? (
              <IoIosCheckmarkCircleOutline size="100px" color="#50d957" />
            ) : (
              <VscError size="100px" color="red" />
            )}
            {swapSlug && (
              <Text
                onClick={() => copyToClipBoard(swapSlug)}
                display={"flex"}
                alignItems={"center"}
                gap={"5px"}>
                Slug: {swapSlug}
                <FaCopy />
              </Text>
            )}
            {swapSlug ? (
              <Text>
                PZP will be deposited in{" "}
                {`"${currentWalletAddress?.slice(0, 4)}...${currentWalletAddress?.slice(-4)}"`}{" "}
                after approximately 24-48 hours.
              </Text>
            ) : (
              <Text>An error has occured, please try again later.</Text>
            )}
          </Box>
        </ModalBody>
        <ModalFooter className="modal-footer">
          <Button onClick={onCloseSwapSuccessfull} className="green-button">
            Close
          </Button>
          <Box className="modal-footer-base"></Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SwapSuccessfull;
