import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Image,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { IoIosWallet, IoMdClose } from "react-icons/io";
import React, { useEffect, useState } from "react";
import { onConnect } from "utils";

const ConnectWallet = ({
  onCloseConnectWallet,
  isOpenConnectWallet,
  coreConnect,
  coreConnectLoading,
}) => {
  async function handleTonOnClick() {
    await onConnect();
    onCloseConnectWallet();
  }
  return (
    <Modal
      size={"sm"}
      isCentered
      motionPreset="slideInBottom"
      onClose={onCloseConnectWallet}
      isOpen={isOpenConnectWallet}>
      <ModalOverlay />
      <ModalContent
        className="modal-content"
        borderRadius={"30px"}
        padding={"0px"}
        mx={"10px"}
        overflow={"hidden"}>
        <ModalHeader
          zIndex={"2"}
          color={"#51A1FE"}
          fill={"#51A1FE"}
          className="modal-header"
          display={"flex"}
          gap={"10px"}
          alignItems={"center"}>
          <IoIosWallet size={"27px"} />
          <Text w={"100%"}>Wallet</Text>
        </ModalHeader>
        <ModalBody className="modal-body">
          <Box className="connect-wallet-container">
            <Text>Choose your wallet in preferred network</Text>
            <Button onClick={handleTonOnClick} className="blue-button">
              TON <Image src="/assets/webp/ton.png" />
            </Button>
            <Button isLoading={coreConnectLoading} onClick={coreConnect} className="white-button">
              CORE <Image src="/assets/webp/core.png" />
            </Button>
          </Box>
        </ModalBody>
        <ModalFooter className="modal-footer">
          <Button onClick={onCloseConnectWallet} className="green-button">
            Close
          </Button>
          <Box className="modal-footer-base"></Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConnectWallet;
