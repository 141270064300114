import {
  Box,
  Button,
  ChakraProvider,
  CircularProgress,
  Image,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { TonConnectButton, useTonWallet } from "@tonconnect/ui-react";
import { useSearchParams } from "react-router-dom";
import useNotification from "hooks/useNotification";
import { IoIosWallet, IoMdArrowDropdown, IoMdCopy, IoMdLogOut } from "react-icons/io";
import ConnectWallet from "./ConnectWallet";
import SwapConfirm from "./SwapConfirm";
import SwapSuccessfull from "./SwapSuccessfull";

const Store = ({ onSubmit, address, balance, actionInProgress, setActionInProgress }) => {
  const [shopList, setShopList] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [cashAmount, setAmount] = useState();
  const [fee, setFee] = useState(10);
  const [errorText, setErrorText] = useState(null);
  const [swapLoading, setSwapLoading] = useState(false);
  const { showNotification } = useNotification();
  const [receivableAmount, setReceivableAmount] = useState(0);
  const [conversionRate, setConversionRate] = useState(1);

  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [coreWalletAddress, setCoreWalletAddress] = useState(null);
  const [coreConnectLoading, setCoreConnectLoading] = useState(false);
  const [coreBalance, setCoreBalance] = useState(0);
  const [cashBalance, setCashBalance] = useState(0);
  const wallet = useTonWallet();
  const [swapSlug, setSwapSlug] = useState(null);
  const [swapLimits, setSwapLimits] = useState(0);

  const [windowObject, setWindowObject] = useState();

  const [currentBalance, setCurrentBalance] = useState(0);

  const {
    isOpen: isOpenConnectWallet,
    onClose: onCloseConnectWallet,
    onOpen: onOpenConnectWallet,
  } = useDisclosure();

  const {
    isOpen: isOpenSwapConfirm,
    onClose: onCloseSwapConfirm,
    onOpen: onOpenSwapConfirm,
  } = useDisclosure();

  const {
    isOpen: isOpenSwapSuccessfull,
    onClose: onCloseSwapSuccessfull,
    onOpen: onOpenSwapSuccessfull,
  } = useDisclosure();

  useEffect(() => {
    if (address) {
      const tonButton = document.querySelector(".ton-connect-button button");
      const tonButtonDiv = document.querySelector(".ton-connect-button button>div");
      var image = document.createElement("img");
      image.src = "/assets/webp/ton.png";
      image.style.width = "18px";
      image.style.height = "18px";
      image.style.marginRight = "3px";

      tonButton.insertBefore(image, tonButtonDiv);

      console.log("useEffect ~ tonButton:", tonButton);
    }

    return () => {
      image?.remove();
    };
  }, [address]);

  async function fetchShopList() {
    const res = await fetch("https://pzgamebot-stage.playzap.games/api/getShopList").then((res) =>
      res.json(),
    );
    setShopList(res);
    console.log("fetchShopList", res);
  }

  async function fetchFees() {
    const res = await fetch("https://pzgamebot-stage.playzap.games/api/getTicketSwapFee").then(
      (res) => res.json(),
    );
    setFee(res.fee_percent);
    console.log("fetchFees", res);
  }

  async function fetchConversionRate() {
    const res = await fetch("https://pzgamebot-stage.playzap.games/api/ticketConversion").then(
      (res) => res.json(),
    );
    setConversionRate(res.pzp);
    console.log("fetchConversionRate", res);
  }

  async function fetchCashBalance() {
    const res = await fetch("https://pzgamebot-stage.playzap.games/api/getBalance", {
      method: "GET",
      headers: {
        "content-type": "application/json",
        authentication: `bearer ${token}`,
      },
    }).then((res) => res.json());

    console.log("fetchCashBalance", res);
    setCashBalance(res.virtual2);
  }

  async function fetchCoreEvmBalance(wallet) {
    const res = await fetch(
      `https://pzgamebot-stage.playzap.games/api/getEvmBalance?walletaddress=${wallet}`,
    ).then((res) => res.json());
    setCoreBalance(res.result.data.balance);
    console.log("fetchFees", res);
  }

  async function swapCash() {
    setSwapLoading(true);
    setSwapSlug(null);
    const endpoint = "/api/ticket-swap";

    const res = await fetch(endpoint, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        token: `${token}`,
      },
      body: JSON.stringify({
        amount: cashAmount,
      }),
    })
      .then((res) => res.json())
      .finally(() => setSwapLoading(false));

    showNotification(`Swap successfull`, "success", undefined, 4000);
    console.log("swapCash ~ windowObject:", windowObject);
    console.log("swapCash ~ res:", res);
    setSwapSlug(res.data.msg.slug);
    fetchSwapLimits();
    onOpenSwapSuccessfull();
    onCloseSwapConfirm();
    if (windowObject.fetchBalance) windowObject?.fetchBalance();
  }

  async function purchaseGemsWithEvm(id, amount) {
    setActionInProgress(true);
    const endpoint = "/api/buy-gems-evm";

    try {
      const res = await fetch(endpoint, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          token: `${token}`,
        },
        body: JSON.stringify({
          amount: amount,
          item_id: id,
        }),
      })
        .then((res) => res.json())
        .finally(() => setActionInProgress(false));

      console.log("swapCash ~ res:", res);
      showNotification(`Gems purchased successfully`, "success", undefined, 4000);
      console.log("purchaseGemsWithEvm ~ windowObject:", windowObject);
    } catch (error) {
      console.log("purchaseGemsWithEvm ~ error:", error);
      showNotification(`Error purchasing gems`, "warning", undefined, 4000);
    }
  }

  async function fetchSwapLimits() {
    const endpoint = "/api/swap-limit";

    try {
      const res = await fetch(endpoint, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          token: `${token}`,
        },
      }).then((res) => res.json());

      console.log("availableLimit ~ res:", res);
      setSwapLimits(res.data.swapLimit.availableLimit);
    } catch (error) {
      console.log("purchaseGemsWithEvm ~ error:", error);
      showNotification(`Error purchasing gems`, "warning", undefined, 4000);
    }
  }

  useEffect(() => {
    fetchShopList();
    fetchFees();
    fetchConversionRate();
    fetchCashBalance();
    fetchSwapLimits();

    setWindowObject(window.top);
    console.log("useEffect ~ window.top:", window.top);
  }, []);

  useEffect(() => {
    if (!cashAmount) {
      setReceivableAmount(0);
      return;
    }

    if (!conversionRate || !fee) return;

    setReceivableAmount(((cashAmount - (cashAmount * fee) / 100) * conversionRate).toFixed(2));
  }, [cashAmount, conversionRate, fee]);

  const handleAmountChange = (e) => {
    const value = e.target.value;
    setAmount(value);

    if (/^\d*$/.test(value) && value.length <= 6) {
      setAmount(value);
    }

    if (value > cashBalance) {
      setErrorText("Insufficient balance");
      return;
    } else if (value <= cashBalance) {
      setErrorText(null);
    }

    console.log(value);

    if (value <= 0 && value) {
      setErrorText("Amount should be greater than 0");
      return;
    } else if (value > 0) {
      setErrorText(null);
    }

    if (swapLimits < value) {
      setErrorText(`Daily limit left ${swapLimits}`);
    } else if (swapLimits >= value) {
      setErrorText(null);
    }
  };

  async function coreConnect() {
    setCoreConnectLoading(true);

    try {
      const res = await fetch("/api/get-create-wallet", {
        method: "GET",
        headers: {
          "content-type": "application/json",
          token: `${token}`,
        },
      })
        .then((res) => res.json())
        .finally(() => setCoreConnectLoading(false));

      console.log("Wallet data", res);
      const wallet = res.data.evm_wallet.address;
      setCoreWalletAddress(wallet);
      onCloseConnectWallet();
      fetchCoreEvmBalance(wallet);
      localStorage.setItem("coreWallet", true);
    } catch (error) {
      console.log(error);
      setCoreWalletAddress(null);
      setCoreConnectLoading(false);
    }
  }

  useEffect(() => {
    const isCore = localStorage.getItem("coreWallet");

    if (isCore) {
      coreConnect();
    }
  }, []);

  useEffect(() => {
    console.log("useEffect ~ wallet:", wallet);

    if (wallet) {
      setIsWalletConnected(true);
    } else {
      setIsWalletConnected(false);
    }
  }, [wallet]);

  useEffect(() => {
    if (!coreWalletAddress && !balance && !coreBalance) return;

    if (coreWalletAddress && coreBalance) {
      setCurrentBalance(coreBalance);
    } else if (balance) {
      setCurrentBalance(balance);
    }
  }, [coreBalance, balance, coreWalletAddress]);

  function copyToClipBoard() {
    navigator.clipboard.writeText(coreWalletAddress);
    showNotification(`Address copied to clipboard`, "success", undefined, 4000);
  }

  return (
    <ChakraProvider>
      <Box className="store-wrapper page-wrapper">
        <ConnectWallet
          coreConnectLoading={coreConnectLoading}
          coreConnect={coreConnect}
          onCloseConnectWallet={onCloseConnectWallet}
          isOpenConnectWallet={isOpenConnectWallet}
        />

        <SwapSuccessfull
          swapSlug={swapSlug}
          tonAddress={address}
          receivableAmount={receivableAmount}
          coreWalletAddress={coreWalletAddress}
          isOpenSwapSuccessfull={isOpenSwapSuccessfull}
          onCloseSwapSuccessfull={onCloseSwapSuccessfull}
          onOpenSwapSuccessfull={onOpenSwapSuccessfull}
        />

        <SwapConfirm
          isOpenSwapSuccessfull={isOpenSwapSuccessfull}
          onCloseSwapSuccessfull={onCloseSwapSuccessfull}
          onOpenSwapSuccessfull={onOpenSwapSuccessfull}
          swapSlug={swapSlug}
          tonAddress={address}
          coreWalletAddress={coreWalletAddress}
          swapLoading={swapLoading}
          cashAmount={cashAmount}
          receivableAmount={receivableAmount}
          swapCash={swapCash}
          onCloseSwapConfirm={onCloseSwapConfirm}
          isOpenSwapConfirm={isOpenSwapConfirm}
        />

        {actionInProgress && (
          <Box className="progress-transaction">
            <CircularProgress isIndeterminate />
          </Box>
        )}

        <Box className="sub-title-container">
          <Box className="store-balance">
            <Box>
              <Image
                src={`/assets/webp/${coreWalletAddress ? "pzp_core" : "pzp_ton"}.png`}
                width={"20px"}
                height={"20px"}
                alt="ton"
              />
              <Text>{currentBalance || 0}</Text>
            </Box>
          </Box>
          <div className="line"></div>
          {!isWalletConnected && !coreWalletAddress && (
            <Button onClick={onOpenConnectWallet} height={"30px"} className="green-button">
              Connect Wallet
            </Button>
          )}

          <TonConnectButton className={`ton-connect-button ${wallet ? "" : "hide"}`} />

          {coreWalletAddress && (
            <Menu className="menu">
              <MenuButton
                height={"30px"}
                className="green-button"
                as={Button}
                rightIcon={<IoMdArrowDropdown />}>
                {`${coreWalletAddress?.slice(0, 4)}...${coreWalletAddress?.slice(-4)}`}
              </MenuButton>
              <MenuList className="menu-list">
                <MenuItem onClick={copyToClipBoard}>
                  <IoMdCopy /> Copy address
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setCoreWalletAddress(null);
                    setCoreBalance(0);
                    localStorage.removeItem("coreWallet");
                  }}>
                  <IoMdLogOut /> Disconnect
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </Box>

        <Tabs index={tabIndex} variant={"unstyled"}>
          <TabList className="tab-list">
            <Tab
              onClick={() => setTabIndex(0)}
              className={tabIndex === 0 ? "tab-button-active" : ""}>
              Buy
              <Image
                objectFit={"contain"}
                src={"/assets/webp/gems-multiple.png"}
                width={"30px"}
                height={"30px"}
                alt="gems"
              />
            </Tab>
            <Tab
              onClick={() => setTabIndex(1)}
              className={tabIndex === 1 ? "tab-button-active" : ""}>
              Redeem
              <Image
                ml={"5px"}
                objectFit={"contain"}
                src={"/assets/webp/cash-to-pzp.png"}
                width={"45px"}
                height={"45px"}
                alt="gems"
              />
            </Tab>
          </TabList>

          <TabPanels className="tab-panel">
            <TabPanel position={"relative"}>
              {!isWalletConnected && !coreWalletAddress ? (
                <Box onClick={onOpenConnectWallet} className="store-coming-soon">
                  <Box>
                    <IoIosWallet />
                    <Text>Connect wallet to view</Text>
                  </Box>
                </Box>
              ) : null}
              <Box className="store-grid">
                {shopList &&
                  shopList.map((denomination, id) => {
                    return (
                      <Box className="store-item" key={id}>
                        <Box className="store-item-amount">
                          <Image
                            objectFit={"contain"}
                            src={`/assets/webp/gem-amounts/${id}.png`}
                            width={"45px"}
                            height={"45px"}
                            alt="gems"
                          />
                          <Text>{denomination.quantity}</Text>
                        </Box>
                        <Button
                          isDisabled={currentBalance < denomination.price}
                          onClick={() => {
                            if (address) {
                              onSubmit(denomination.price, denomination.item_id);
                            } else {
                              purchaseGemsWithEvm(denomination.item_id, denomination.price);
                            }
                          }}
                          className="green-button">
                          <Image
                            src={`/assets/webp/${coreWalletAddress ? "pzp_core" : "pzp_ton"}.png`}
                            width={"20px"}
                            height={"20px"}
                            alt="ton"
                          />
                          {denomination.price}
                        </Button>
                      </Box>
                    );
                  })}
              </Box>
            </TabPanel>
            <TabPanel position={"relative"}>
              {!isWalletConnected && !coreWalletAddress ? (
                <Box onClick={onOpenConnectWallet} className="store-coming-soon">
                  <Box>
                    <IoIosWallet />
                    <Text>Connect wallet to view</Text>
                  </Box>
                </Box>
              ) : null}
              <Box className="swap-container">
                <Box className="swap-input-container">
                  <Input
                    onChange={(e) => handleAmountChange(e)}
                    type="number"
                    min="0"
                    max="999999"
                    maxLength="6"
                    pattern="\d{6}"
                    inputMode="numeric"
                    className="swap-input-box"
                    placeholder="Enter the Amount"
                    value={cashAmount}
                  />
                  <Image src={"/assets/webp/cash.png"} width={"30px"} height={"30px"} alt="ton" />
                  <Text color={"red.500"} className="error-text">
                    {errorText}
                  </Text>
                </Box>
                <Box className="swap-amount-structure">
                  <Text>Fee:</Text>
                  <Box>
                    <Text>{fee}%</Text>
                  </Box>
                </Box>
                <Box className="swap-amount-structure">
                  <Text>Total Receivable:</Text>
                  <Box>
                    <Image
                      src={`/assets/webp/${coreWalletAddress ? "pzp_core" : "pzp_ton"}.png`}
                      width={"25px"}
                      height={"25px"}
                      alt="ton"
                    />
                    <Text>{receivableAmount}</Text>
                  </Box>
                </Box>
              </Box>
              <Button
                isDisabled={(!address && !coreWalletAddress) || errorText || !cashAmount}
                isLoading={swapLoading}
                onClick={onOpenSwapConfirm}
                className="green-button">
                Redeem
              </Button>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </ChakraProvider>
  );
};

export default Store;
